import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Move-Up Home Buyers in Minnesota | Thistle Financial"
        description="Ready to buy a larger, more expensive home? At Thistle Financial, we specialize in loans for the move-up buyer."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>Move-Up Buyers in Minnesota</h1>
        <p>
          At Thistle, we specialize in aiding the move-up buyer interested in
          “moving up” by purchasing a larger and more expensive home. If you’re
          ready to upgrade your style of living, we can help you realize your
          dream of a better home.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>What to Take Into Consideration</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="How Much Can You Afford?"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="How Much Is Your House Worth?"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Should You Buy Or Sell First?"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-4"}
                  title="Financing Options"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">How Much Can You Afford?</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  It's easy to get excited about a bigger and more expensive
                  home, so it's essential to take a close look at your budget
                  before you start shopping. In addition to a larger mortgage
                  payment, there'll be additional costs that may include higher
                  utilities, increased maintenance costs, and perhaps HOA fees
                  if you're purchasing a condo.
                </p>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">How Much Is Your House Worth?</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Don't guess when it comes to your home's value. You might
                  optimistically believe that it's worth more than it actually
                  is, which can throw off your financial plans to purchase a
                  more expensive home. As a move-up buyer, you'll want an
                  accurate market value going in, so you're working with real
                  numbers.
                </p>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Should You Buy Or Sell First?</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Choosing whether to buy or sell first is the toughest decision
                  move-up buyers need to make. There's a lot at stake, and
                  unfortunately, there's no easy answer. You'll need to decide
                  whether you're comfortable with the risks associated with each
                  decision. We can help you explore the pros and cons.
                </p>
              </div>

              <div
                id="anchor-4"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6"
              >
                <h3 className="mb-1">Financing Options</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  At Thistle, we can help you examine your financing options if
                  you're interested in moving up. For example, some options can
                  cover any gap between listing your home and buying a new one.
                  You may qualify for a Bridge Loan or Home Equity Line of
                  Credit to give you some breathing room.
                </p>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <ValueProps />
      <About className="mb-0" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Move Up Buyers.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Move Up Buyers.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "2.3 Move-Up buyers/1.0 Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
